import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { PageHeader, BannerLeft } from "../utils"
import patioImg from "../images/gallery/patio/patio-landscape-1.jpg"

const SpecialsPage = () => (
  <Layout>
    <Seo
      title="Specials"
      keywords={[
        `azalea bar & kitchen special offers`,
        `breakfast lunch dinner in asheville nc`,
        `restaurants near biltmore in asheville nc`,
      ]}
    />

    <PageHeader img={patioImg}>
      <BannerLeft
        title="Specials"
        subtitle="Daily Specials Coming Soon, Fall 2024"
      >
      </BannerLeft>
    </PageHeader>
  </Layout>
)

export default SpecialsPage
